
/*
 * Modules
 */

 // scroll change hero to header

$(window).scroll(function (event) {
    let scroll = $(window).scrollTop();
    console.log(scroll);
    if(scroll >= 100 && !$(".hero__content").hasClass("hero__content--scroll")) {
    	$(".hero__content").addClass("hero__content--scroll");
    } else if (scroll < 100 && $(".hero__content").hasClass("hero__content--scroll")) {
    	$(".hero__content").removeClass("hero__content--scroll");
    }
});


$('#switcha').change(function()
{
    if(this.checked)
    {
        $('.section__buoy').hide();
        $('.section__stat').show();
    }
    else
    {
        $('.section__buoy').show();
        $('.section__stat').hide();
    }
});


let buoys = null;

$(function()
{
    $.ajax({
        contentType: 'application/json',
        data: {},
        dataType: 'json',
        success: function(data){
            buoys = data;

            for(b of buoys)
            {
                $('#section__buoy').append("<div class='section__row'>\
						<div class='section__head'>\
							<h3>Buoy #"+b['id']+"</h3>\
							<figure class='section__img'>\
								<img src='assets/images/logo.png' alt=''>\
							</figure><!-- /.section__img -->\
						</div><!-- /.section__head -->\
						<div class='section__data'>\
							<div class='section__coord'>\
								<p>\
									Last updated: <span class='section__bdata'>"+b['last_connection']+"</span>;<span class='section__space'></span>\
									Connected station: <span class='section__bdata'>Station #2</span>\
								</p>\
								<p>\
									Wave lenght: <span class='section__bdata'>smth</span>;<span class='section__space'></span>\
									Wave Frequecy: <span class='section__bdata'>smth</span>;<span class='section__space'></span>\
									Wave height: <span class='section__bdata'>Sea state code</span>;<span class='section__space'></span>\
									Water temperature: <span class='section__bdata'>24</span>;<span class='section__space'></span>\
								</p>\
								<p>Coord: <span class='section__bdata'>42.07869, 29.97860</span></p>\
							</div><!-- /.section__coord -->\
						</div><!-- /.section__data -->\
						<div class='section__actions'>\
							<div class='section__status'></div><!-- /.section__status -->\
							<div data-battery='70%' title='70%' class='section__inf'><div class='section__infBG section__infOK'></div><!-- /.section__infBG -->Working</div><!-- /.section__inf section__inf -->\
							<button class='section__rem'>remove</button>\
						</div><!-- /.section__actions -->\
						<div class='section__open'></div><!-- /.section__open -->\
					</div><!-- /.section__row -->");
            }
        },
        error: function(){
            alert("1");
        },
        type: 'GET',
        url: 'https://smartbuoy.cean.dev/api/get_buoys.php'
    });
});